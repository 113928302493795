import classNames from 'classnames';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import AnimatedInViewImage from 'components/animated-in-view-image';
import Container from 'components/container';
import Hr from 'components/hr';
import NumberedTextBlocks from 'components/numbered-text-blocks';
import TextBlock from 'components/text-block';
import Vr from 'components/vr';
import { isEven } from 'utils/math';

const SplitTextBlockAndMedia = ({ isNumbered, items }) => {
  function renderImage(image, isImageLeft) {
    return (
      <div
        className={classNames('-mx-page-mx p-channel h-full', {
          'lg:mr-0': isImageLeft,
          'lg:ml-0 lg:pl-0': !isImageLeft,
        })}
      >
        <AnimatedInViewImage
          className='object-cover absolute inset-0'
          containerClassName='-lg:h-[calc(100vw-32px)] -lg:max-h-[428px] mx-auto relative lg:h-full lg:min-h-[50vh]'
          image={image}
        />
      </div>
    );
  }

  function renderText(textBlock, index, isImageLeft) {
    return (
      <div className='flex items-center h-full'>
        <div
          className={classNames('w-full', {
            'py-16': !isNumbered,
          })}
        >
          {isNumbered ? (
            <NumberedTextBlocks
              col={isImageLeft ? 'r' : 'l'}
              forceIndices={[index]}
              items={[{ ...textBlock }]}
              size='md'
            />
          ) : (
            <TextBlock
              as='h3'
              className='mx-auto xl:max-w-4/6'
              size='md'
              {...textBlock}
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <Container>
      {items.map(({ textBlock, image }, index) => {
        const isImageLeft = isEven(index);

        return (
          <React.Fragment key={index}>
            <Hr className='-mx-page-mx' />
            <div className='grid items-center lg:gap-gutter lg:grid-cols-2'>
              <div
                className={classNames('flex h-full', {
                  '-lg:order-2': isImageLeft,
                })}
              >
                <div className='flex-1 h-full'>
                  {isImageLeft
                    ? renderImage(image, isImageLeft)
                    : renderText(textBlock, index, isImageLeft)}
                </div>
                <Vr className='-lg:hidden h-full' />
              </div>

              {isImageLeft
                ? renderText(textBlock, index, isImageLeft)
                : renderImage(image, isImageLeft)}
            </div>
          </React.Fragment>
        );
      })}
    </Container>
  );
};

SplitTextBlockAndMedia.propTypes = {
  componentIndex: PropTypes.number.isRequired,
  isNumbered: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      textBlock: PropTypes.shape(TextBlock.propTypes),
      image: PropTypes.shape(AnimatedInViewImage.propTypes.image).isRequired,
    })
  ).isRequired,
};

export default SplitTextBlockAndMedia;

export const query = graphql`
  fragment SplitTextBlockAndMedia on WpPage_Pagebuilder_Components_SplitTextBlockAndMedia {
    fieldGroupName
    isNumbered
    items {
      textBlock {
        preHeading
        heading
        subheading
        body
      }
      image {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 1920
              height: 1920
              transformOptions: { cropFocus: CENTER }
            )
          }
        }
      }
    }
  }
`;
