import classNames from 'classnames';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'components/button';
import Container from 'components/container';
import Heading from 'components/heading';
import Hr from 'components/hr';
import TextBlock from 'components/text-block';
import TextBlockAndButtons from 'components/text-block-and-buttons';
import Vr from 'components/vr';
import { isEven } from 'utils/math';

const WhatWeDo = ({ otherServices, services, textBlock }) => {
  return (
    <>
      <Hr />
      <Container className='grid lg:grid-cols-2 lg:gap-x-4'>
        <div className='flex'>
          <div className='flex-1 lg:mr-page-mx xl:mr-0'>
            <div className='col-span-4 my-10 lg:my-20'>
              <div className='mx-auto xl:max-w-4/6'>
                <TextBlock
                  as='h2'
                  className='mb-10 lg:mb-20'
                  size='md'
                  {...textBlock}
                />
              </div>
            </div>
          </div>

          <Vr className='-lg:hidden' />
        </div>

        <Hr className='lg:hidden -mx-page-mx' />

        <div className='flex'>
          <div className='flex-1 lg:ml-page-mx xl:ml-0 -lg:grid -lg:gap-x-4 -lg:grid-cols-4'>
            <div className='flex justify-end'>
              <Vr className='col-span-1 lg:hidden' />
            </div>
            <div className='col-span-3 lg:mt-20'>
              <ul>
                {services.map(({ links, textBlock }, id) => {
                  return (
                    <li key={id}>
                      <TextBlockAndButtons
                        className='py-10'
                        links={links}
                        size='sm'
                        textBlock={textBlock}
                      />
                      <Hr className='-mx-page-mx -ml-4' />
                    </li>
                  );
                })}
              </ul>
              <section className='py-10 xl:max-w-[540px]'>
                <Heading
                  as='h3'
                  className='mb-4'
                  html={otherServices.heading}
                  size='xl'
                />
                <ul className='grid xl:grid-cols-2 items-end my-10'>
                  {otherServices.items.map(({ item }, index) => {
                    const itemLeft = isEven(index);

                    return (
                      <li
                        key={index}
                        className={classNames('mb-4', {
                          'xl:text-right': !itemLeft,
                          'xl:text-left': itemLeft,
                        })}
                      >
                        <span className='font-normal my-8px text-[24px]'>
                          {item}
                        </span>
                      </li>
                    );
                  })}
                </ul>
                {otherServices.link && (
                  <Button
                    label={otherServices.link.title}
                    to={otherServices.link.url}
                    variant='link'
                  />
                )}
              </section>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

WhatWeDo.propTypes = {
  otherServices: PropTypes.shape({
    heading: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        item: PropTypes.string,
      })
    ),
    link: PropTypes.shape({
      target: PropTypes.string,
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  }),
  textBlock: PropTypes.shape(TextBlock.propTypes),
  services: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.shape({
        target: PropTypes.string,
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
      textBlock: PropTypes.shape(TextBlock.propTypes),
    })
  ),
};

export default WhatWeDo;

export const query = graphql`
  fragment WhatWeDo on WpPage_Pagebuilder_Components_WhatWeDo {
    fieldGroupName
    otherServices {
      fieldGroupName
      heading
      items {
        fieldGroupName
        item
      }
      link {
        target
        title
        url
      }
    }
    services {
      fieldGroupName
      links {
        link {
          target
          title
          url
        }
      }
      textBlock {
        body
        heading
        preHeading
        subheading
        fieldGroupName
      }
    }
    textBlock {
      body
      fieldGroupName
      heading
      preHeading
      subheading
    }
  }
`;
