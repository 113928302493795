import { graphql } from 'gatsby';
import upperFirst from 'lodash/upperFirst';
import PropTypes from 'prop-types';
import React from 'react';

import Container from 'components/container';
import Hr from 'components/hr';
import TextBlock from 'components/text-block';
import { useDictionary } from 'hooks';

const LegalDocument = ({ title, lastUpdated, body }) => {
  const { lastUpdated: lastUpdatedDictionary } = useDictionary();

  return (
    <>
      <Hr />
      <Container className='my-page-my'>
        <div className='max-w-[768px] mx-auto'>
          <TextBlock
            body={body}
            bodyClassName=''
            heading={title}
            size='md'
            subheading={`${upperFirst(lastUpdatedDictionary)}: ${lastUpdated}`}
          />
        </div>
      </Container>
    </>
  );
};

LegalDocument.propTypes = {
  title: PropTypes.string.isRequired,
  lastUpdated: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
};

export default LegalDocument;

export const query = graphql`
  fragment LegalDocument on WpPage_Pagebuilder_Components_LegalDocument {
    fieldGroupName
    title
    lastUpdated
    body
  }
`;
