import PropTypes from 'prop-types';
import React from 'react';

import Button from 'components/button';
import TextBlock from 'components/text-block';

const TextBlockAndButtons = ({
  className = '',
  links,
  size = 'md',
  textBlock,
}) => {
  return (
    <div className={`${className}`}>
      <div className='2xl:max-w-[85%]'>
        <TextBlock align='left' as='h2' size={size} {...textBlock} />
      </div>
      <ul className='flex justify-between gap-gutter mt-9'>
        {(links ? links : []).map((item, id) => {
          const { link } = item;

          return (
            <li key={id} className='flex-auto'>
              <Button label={link.title} to={link.url} variant='link' />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

TextBlockAndButtons.propTypes = {
  className: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.shape({
        target: PropTypes.string,
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
    })
  ),
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  textBlock: PropTypes.shape(TextBlock.propTypes),
};

export default TextBlockAndButtons;
