/**
 * Map Component
 *
 * Renders a Google Map with data from an ACF Google Map field.
 *
 * Reference:
 * - https://www.advancedcustomfields.com/resources/google-map/
 * - https://developers.google.com/maps/documentation/javascript/
 * - https://react-google-maps-api-docs.netlify.app/
 *
 */

import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import { upperFirst } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { getWindow } from 'ssr-window';

import { useDictionary } from 'hooks';
import { useWindowSize } from 'hooks';
import marker from 'images/marker.svg';
import { mapStyles } from 'styles';

const Map = ({ markers, className = 'flex justify-center w-full h-full' }) => {
  const windowSize = useWindowSize();
  const [map, setMap] = useState(null);

  const { loading } = useDictionary();

  const containerStyle = {
    width: '100%',
    height: '100%',
  };

  const mapOptions = {
    styles: mapStyles,
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: true,
    zoomControlOptions: {
      position: getWindow().google?.maps.ControlPosition.TOP_RIGHT,
    },
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.GATSBY_GOOGLE_API_KEY,
  });

  const onLoad = useCallback((map) => setMap(map), []);

  useEffect(() => {
    if (map) {
      const w = getWindow();
      const bounds = new w.google.maps.LatLngBounds();

      markers.forEach(({ position }) => bounds.extend(position));
      map.fitBounds(bounds);
      map.setCenter(bounds.getCenter());
    }
  }, [map, markers, windowSize.width]);

  return (
    <div className={className}>
      {isLoaded ? (
        <GoogleMap
          center={markers[0].position}
          mapContainerStyle={containerStyle}
          options={mapOptions}
          zoom={6}
          onLoad={onLoad}
        >
          {markers.map(({ id, position }) => (
            <Marker
              key={id}
              icon={{
                url: marker,
                fillColor: '#225c4f',
                fillOpacity: 1,
              }}
              position={position}
            />
          ))}
        </GoogleMap>
      ) : (
        <div
          className='flex items-center justify-center'
          style={containerStyle}
        >
          <p>{upperFirst(loading)}&hellip;</p>
        </div>
      )}
    </div>
  );
};

Map.propTypes = {
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      position: PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
      }),
    })
  ),
  className: PropTypes.string,
};

export default Map;
