import isString from 'lodash/isString';
import last from 'lodash/last';
import upperFirst from 'lodash/upperFirst';

/**
 * Get a component's name from an ACF flex row GraphQL reponse.
 *
 * @export
 * @param {Object} component - An object with at least a `fieldGroupName` property formatted like `WpPage_Pagebuilder_Components_Example`.
 * @return {Undefined|String} The component name, e.g., `Example`, or undefined if a string wasn't provided.
 */
export function getComponentName(component) {
  if (!isString(component?.fieldGroupName)) return undefined;

  return upperFirst(last(component.fieldGroupName.split('_')));
}

/**
 * Get a key for React maps from an ACF flex row GraphQL response.
 *
 * @export
 * @param {Object} component - An object with at least a `fieldGroupName` property formatted like `WpPage_Pagebuilder_Components_Example`.
 * @param {Number} index - The index of this component in the list currently being mapped.
 * @return {String} A reliably unique react map key.
 */
export function getComponentKey(component, index) {
  return `${component.fieldGroupName}-${index}`;
}
