import classNames from 'classnames';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Body from 'components/body';
import Container from 'components/container';
import Heading from 'components/heading';
import Hr from 'components/hr';

const LogoList = ({ heading, body, textSize, items }) => {
  return (
    <>
      <Hr />
      <Container
        className={classNames({
          'my-12 lg:my-20': textSize === 'sm',
          'my-20 lg:my-24': textSize === 'lg',
        })}
      >
        <Heading
          as='h3'
          className={classNames('mb-4', {
            'text-center': textSize === 'lg',
          })}
          html={heading}
          size={textSize === 'sm' ? 'lg' : '4xl'}
          type={textSize === 'sm' ? 'sans' : 'serif'}
        />
        <Body
          className='max-w-[54ch] mx-auto text-center'
          html={body}
          size='lg'
        />
        <ul className='grid-cols-2 gap-gutter md:grid my-12 lg:my-20'>
          {items.map((item, index) => (
            <li
              key={index}
              className='grid grid-cols-2 gap-gutter logo-list-item'
            >
              <div
                className={classNames('logo-list-item-contents', {
                  '-md:order-2': (index + 1) % 2 === 0,
                })}
              >
                <div className='aspect-square md:aspect-video text-center'>
                  <img
                    alt={item.logo.altText}
                    className='w-full h-full'
                    src={item.logo.mediaItemUrl}
                  />
                </div>
              </div>
              <div />
            </li>
          ))}
        </ul>
      </Container>
    </>
  );
};

LogoList.propTypes = {
  heading: PropTypes.string.isRequired,
  body: PropTypes.string,
  textSize: PropTypes.oneOf(['sm', 'lg']),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      logo: PropTypes.shape({
        altText: PropTypes.string,
        mediaItemUrl: PropTypes.string,
        localFile: PropTypes.shape({
          absolutePath: PropTypes.string.isRequired,
        }),
      }),
    })
  ),
};

export default LogoList;

export const query = graphql`
  fragment LogoList on WpPage_Pagebuilder_Components_LogoList {
    fieldGroupName
    heading
    body
    textSize
    items {
      logo {
        altText
        mediaItemUrl
        localFile {
          absolutePath
        }
      }
    }
  }
`;
