import classNames from 'classnames';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Container from 'components/container';
import Hr from 'components/hr';
import TextBlock from 'components/text-block';
import Vr from 'components/vr';

const HeroWithTextBlocks = ({ items, textBlock }) => {
  return (
    <>
      <Hr />
      <Container className='grid-cols-2 gap-gutter lg:grid'>
        <div className='gap-gutter flex-row lg:flex xl:grid grid-cols-6'>
          <div className='-xl:hidden' />
          <TextBlock
            className='my-12 lg:my-20 col-span-4 lg:mr-page-mx xl:mr-0'
            size='md'
            {...textBlock}
          />
          <Vr className='justify-self-end -lg:hidden' />
        </div>
        <ul className='mt-12 pb-12 lg:mt-20 lg:pb-20'>
          {items.map((item, index) => (
            <li
              key={index}
              className={classNames('mt-6', {
                'lg:mt-0': index === 0,
              })}
            >
              {index < items.length ? (
                <Hr
                  className={classNames('mb-6 -mx-page-mx lg:-ml-gutter', {
                    'lg:hidden': index === 0,
                  })}
                />
              ) : null}
              <TextBlock
                body={item.textBlock.body}
                heading={item.textBlock.heading}
              />
            </li>
          ))}
        </ul>
      </Container>
    </>
  );
};

HeroWithTextBlocks.propTypes = {
  textBlock: PropTypes.shape(TextBlock.propTypes),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      textBlock: PropTypes.shape(TextBlock.propTypes),
    })
  ).isRequired,
};

export default HeroWithTextBlocks;

export const query = graphql`
  fragment HeroWithTextBlocks on WpPage_Pagebuilder_Components_HeroWithTextBlocks {
    fieldGroupName
    items {
      fieldGroupName
      textBlock {
        body
        fieldGroupName
        heading
        preHeading
        subheading
      }
    }
    textBlock {
      body
      fieldGroupName
      heading
      preHeading
      subheading
    }
  }
`;
