/* eslint-disable jsx-a11y/media-has-caption */
import classNames from 'classnames';
import defaults from 'lodash/defaults';
import mime from 'mime/lite';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useInViewport } from 'react-in-viewport';
import { useSpring, animated } from 'react-spring';

import { ReactComponent as Spinner } from 'images/spinner.svg';
import { default as defaultSpring } from 'springs';

const Video = ({
  webm,
  mp4,
  image,
  controls = true,
  playsInline = true,
  springConfig = {},
  ...props
}) => {
  const [isPlaying, setIsPlaying] = useState(!props.autoPlay);
  const ref = useRef();
  const { inViewport } = useInViewport(ref, { threshold: 0.35 });

  const animStyles = useSpring({
    config: defaults({}, springConfig, defaultSpring),
    from: { height: '100%' },
    to: inViewport && isPlaying && { height: '0%' },
    delay: 200,
  });

  useEffect(() => {
    if (ref.current.currentTime > 0) setIsPlaying(true);
  }, [ref]);

  return (
    <>
      <video
        ref={ref}
        controls={controls}
        playsInline={playsInline}
        poster={image ? image.sourceUrl : ''}
        {...props}
        onPlay={() => setIsPlaying(true)}
      >
        {webm && <source src={webm.mediaItemUrl} type='video/webm' />}
        {mp4 && <source src={mp4.mediaItemUrl} type='video/mp4' />}
        {image && (
          <source
            alt={image.altText}
            src={image.sourceUrl}
            type={mime.getType(image)}
          />
        )}
      </video>
      <animated.div
        className='bg-white-off absolute inset-0 mt-auto'
        style={animStyles}
      />
      <div className='absolute inset-0 flex items-center justify-center'>
        <Spinner
          className={classNames(
            'h-[38px] w-[38px] transition-opacity duration-300',
            {
              'opacity-0': isPlaying,
            }
          )}
        />
      </div>
    </>
  );
};

Video.propTypes = {
  webm: PropTypes.shape({
    mediaItemUrl: PropTypes.string,
  }),
  mp4: PropTypes.shape({
    mediaItemUrl: PropTypes.string,
  }),
  image: PropTypes.shape({
    sourceUrl: PropTypes.string,
    altText: PropTypes.string,
  }),
  controls: PropTypes.bool,
  playsInline: PropTypes.bool,
  springConfig: PropTypes.object,
  props: PropTypes.object,
};

export default Video;
