import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Hr from 'components/hr';
import RichText from 'components/rich-text';
import { replaceFirst, replaceLast } from 'utils/string';

const widthClasses = {
  sm: 'max-w-md-ch',
  md: 'max-w-lg-ch',
  lg: 'max-w-xl-ch',
};

const Quote = ({ author, quote, width = 'md' }) => {
  return (
    <>
      <Hr />
      <figure className='m-channel py-24 md:py-28 bg-green text-white text-center'>
        <blockquote className='font-serif flex flex-col mx-page-mx'>
          <RichText
            balanceBody={true}
            classNames={{
              p: `text-xl mx-auto ${widthClasses[width]}`,
            }}
            html={replaceFirst(
              replaceLast(quote, '</p>', '&thinsp;”<p>'),
              '<p>',
              '<p>“&thinsp;'
            )}
          />
        </blockquote>
        <figcaption className='text-lg mt-10'>{author}</figcaption>
      </figure>
    </>
  );
};

Quote.propTypes = {
  author: PropTypes.string.isRequired,
  quote: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default Quote;

export const query = graphql`
  fragment Quote on WpPage_Pagebuilder_Components_Quote {
    author
    fieldGroupName
    quote
    width
  }
`;
