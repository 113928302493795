import classNames from 'classnames';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Container from 'components/container';
import Cta from 'components/cta';
import Hr from 'components/hr';
import TextBlockAndButtons from 'components/text-block-and-buttons';
import Vr from 'components/vr';

const CtaGrid = ({ items }) => {
  return (
    <Container>
      <ul className='grid-cols-2 gap-x-gutter lg:grid'>
        {items.map((item, index) => {
          const { links, textBlock } = item;

          return (
            <li key={index}>
              <Hr
                className={classNames('-mx-page-mx', {
                  'lg:mr-0': index % 2 === 0,
                  'lg:-ml-gutter': index % 2 !== 0,
                })}
              />
              <div className='justify-between grid-cols-6 gap-gutter lg:grid h-full'>
                {index % 2 !== 0 && <div className='-lg:hidden' />}
                <TextBlockAndButtons
                  className='py-16 col-span-4'
                  links={links}
                  textBlock={textBlock}
                />
                {index % 2 === 0 && <div className='-lg:hidden' />}
                {index % 2 === 0 && (
                  <Vr className='-lg:hidden py-16 justify-self-end' />
                )}
              </div>
            </li>
          );
        })}
        {items.length % 2 !== 0 && (
          <Hr className='-lg:hidden -ml-gutter -mr-page-mx' />
        )}
      </ul>
    </Container>
  );
};

CtaGrid.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      cta: PropTypes.shape(Cta.propTypes),
    })
  ).isRequired,
};

export default CtaGrid;

export const query = graphql`
  fragment CtaGrid on WpPage_Pagebuilder_Components_CtaGrid {
    fieldGroupName
    items {
      fieldGroupName
      links {
        fieldGroupName
        link {
          target
          title
          url
        }
      }
      textBlock {
        body
        fieldGroupName
        heading
        preHeading
        subheading
      }
    }
  }
`;
