export function replaceFirst(str, search, replace) {
  return str.replace(search, replace);
}

export function replaceLast(str, search, replace) {
  const index = str.lastIndexOf(search);

  if (!index) return str;

  return `${str.substr(0, index)}${replace}${str.substr(
    index + search.length
  )}`;
}
