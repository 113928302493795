import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Builder from 'components/builder';

const Page = ({ data }) => {
  const {
    wpPage: {
      pageBuilder: { components },
    },
  } = data;

  return (
    <article>
      <Builder className='mb-3' components={components} />
    </article>
  );
};

Page.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Page;

export const query = graphql`
  query PageById($id: String) {
    wpPage(id: { eq: $id }) {
      title
      pageBuilder {
        components {
          ...TextBlockAndContactForm
          ...ContactHero
          ...Cta
          ...CtaGrid
          ...Hero
          ...HeroWithTextBlocks
          ...InvestmentTextBlockWithTextBlocks
          ...JobListings
          ...LegalDocument
          ...LogoList
          ...Opportunity
          ...PrincipleWithTextBlocks
          ...PrinciplesWithMedia
          ...Quote
          ...ReversedTextBlockWithTextBlocks
          ...SimpleTextBlock
          ...SplitTextBlockAndMedia
          ...TeamMemberList
          ...TextBlockGrid
          ...TextBlockWithNumberedTextBlocks
          ...Timeline
          ...WhatWeDo
        }
      }
      ...SeoPage
    }
  }
`;
