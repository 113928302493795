import classNames from 'classnames';
import { graphql } from 'gatsby';
import upperFirst from 'lodash/upperFirst';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import Button from 'components/button';
import Container from 'components/container';
import Heading from 'components/heading';
import HeadlessButton from 'components/headless-button';
import Hr from 'components/hr';
import Vr from 'components/vr';
import { useDictionary, useWindowSize } from 'hooks';
import template from 'utils/template';

const JobListings = ({ heading, items }) => {
  const {
    learnMore,
    location: locationDictonary,
    positions,
    phraseNoJobListings,
    phraseShowMoreItem,
  } = useDictionary();

  const { hasSizeLg } = useWindowSize();
  const isMobile = useMemo(() => !hasSizeLg, [hasSizeLg]);

  const ITEMS_PER_GROUP = 6;

  const [itemsToShow, setItemsToShow] = useState(ITEMS_PER_GROUP);

  useEffect(() => {
    setItemsToShow(isMobile ? ITEMS_PER_GROUP : items?.length);
  }, [isMobile, items?.length]);

  const renderItems = (jsx) =>
    items.slice(0, itemsToShow).map((item, index) => jsx(item, index));

  const showMore = () => setItemsToShow((prev) => prev + ITEMS_PER_GROUP);

  const content =
    items?.length > 0 ? (
      <div className='flex'>
        <div className='flex-1 grid-cols-2 gap-gutter md:grid lg:block'>
          {renderItems(
            ({ careerLocation: { location }, title, uri }, index) => (
              <div key={index}>
                <article className='grid-cols-4 gap-gutter lg:grid'>
                  <div className='-lg:pb-2 col-span-2'>
                    <div className='col-span-2 flex flex-row h-full'>
                      <Heading
                        as='h3'
                        className='flex-1 py-8'
                        html={title}
                        type='sans'
                      />
                      <Vr className='-lg:hidden' />
                    </div>
                  </div>
                  <div className='-lg:pb-3 h-full flex-row items-center justify-center lg:flex'>
                    <span className='font-medium'>
                      {upperFirst(locationDictonary)}
                    </span>
                    : {location}
                  </div>
                  <div className='h-full flex-row lg:flex'>
                    <Vr className='-lg:hidden' />
                    <div className='flex-1 items-end justify-center lg:flex'>
                      <div className='lg:w-[80%] mx-auto'>
                        <Button
                          label={upperFirst(learnMore)}
                          to={uri}
                          variant='link'
                        />
                      </div>
                    </div>
                  </div>
                </article>
                <div
                  className={classNames('-md:-mx-page-mx mt-12 relative', {
                    hidden: index === items.length - 1,
                  })}
                >
                  <Hr className='absolute left-channel right-channel md:hidden lg:block lg:left-0 lg:right-0' />
                </div>
              </div>
            )
          )}
        </div>
        <div className='self-stetch -lg:hidden'>
          <Vr />
        </div>
      </div>
    ) : (
      <p className='pt-5 text-lg lg:pt-10 lg:text-xl'>{phraseNoJobListings}</p>
    );

  return (
    <>
      <Hr />
      <Container className='grid-cols-12 py-10 lg:py-20 xl:grid'>
        <div className='-xl:hidden' />
        <div className='col-span-10'>
          <Heading
            className='-lg:text-center mb-5 lg:mb-10'
            html={heading}
            size='3xl'
          />
          <div className='-lg:-mx-page-mx relative'>
            <Hr className='absolute left-channel right-channel lg:left-0 lg:right-0' />
          </div>
          {content}
          <div
            className={classNames({
              hidden: itemsToShow >= items?.length || !isMobile || !items,
            })}
          >
            <HeadlessButton
              className='text-l font-medium py-4 block mx-auto'
              onPress={showMore}
            >
              {template(phraseShowMoreItem)({ item: positions })}
            </HeadlessButton>
          </div>
        </div>
        <div className='-xl:hidden' />
      </Container>
    </>
  );
};

JobListings.propTypes = {
  heading: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      careerLocation: PropTypes.shape({
        location: PropTypes.string,
      }).isRequired,
      title: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
    })
  ),
};

export default JobListings;

export const query = graphql`
  fragment JobListings on WpPage_Pagebuilder_Components_JobListings {
    fieldGroupName
    heading
    items {
      ... on WpCareer {
        careerLocation {
          location
        }
        title
        uri
      }
    }
  }
`;
