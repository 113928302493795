import PropTypes from 'prop-types';
import React from 'react';

import ContactHero from 'components/contact-hero';
import Cta from 'components/cta';
import CtaGrid from 'components/cta-grid';
import Hero from 'components/hero';
import HeroWithTextBlocks from 'components/hero-with-text-blocks';
import InvestmentTextBlockWithTextBlocks from 'components/investment-text-block-with-text-blocks';
import JobListings from 'components/job-listings';
import LegalDocument from 'components/legal-document';
import LogoList from 'components/logo-list';
import Opportunity from 'components/opportunity';
import PrincipleWithTextBlocks from 'components/principle-with-text-blocks';
import PrinciplesWithMedia from 'components/principles-with-media';
import Quote from 'components/quote';
import ReversedTextBlockWithTextBlocks from 'components/reversed-text-block-with-text-blocks';
import SimpleTextBlock from 'components/simple-text-block';
import SplitTextBlockAndMedia from 'components/split-text-block-and-media';
import TeamMemberList from 'components/team-member-list';
import TextBlockAndContactForm from 'components/text-block-and-contact-form';
import TextBlockGrid from 'components/text-block-grid';
import TextBlockWithNumberedTextBlocks from 'components/text-block-with-numbered-text-blocks';
import Timeline from 'components/timeline';
import WhatWeDo from 'components/what-we-do';
import { getComponentKey, getComponentName } from 'utils/acf.js';
import { maybeRenderError } from 'utils/errors';
import { hasOwnProperty } from 'utils/object';

const allComponents = {
  ContactHero,
  Cta,
  CtaGrid,
  Hero,
  HeroWithTextBlocks,
  InvestmentTextBlockWithTextBlocks,
  JobListings,
  LegalDocument,
  LogoList,
  Opportunity,
  PrinciplesWithMedia,
  PrincipleWithTextBlocks,
  Quote,
  ReversedTextBlockWithTextBlocks,
  SimpleTextBlock,
  SplitTextBlockAndMedia,
  TeamMemberList,
  TextBlockAndContactForm,
  TextBlockGrid,
  TextBlockWithNumberedTextBlocks,
  Timeline,
  WhatWeDo,
};

const Builder = ({ components }) => {
  return (
    <>
      {components.map((component, index) => {
        const componentName = getComponentName(component);

        if (!componentName)
          return maybeRenderError(
            `Could not get component name from component data: ${JSON.stringify(
              component
            )}.`
          );
        if (!hasOwnProperty(allComponents, componentName))
          return maybeRenderError(
            `Could not find a builder component for <${componentName} />`
          );
        const Component = allComponents[getComponentName(component)];

        return (
          <Component
            key={getComponentKey(component, index)}
            componentIndex={index}
            {...component}
          />
        );
      })}
    </>
  );
};

Builder.propTypes = {
  components: PropTypes.array.isRequired,
};

export default Builder;
