import classNames from 'classnames';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import AnimatedInViewImage from 'components/animated-in-view-image';
import Container from 'components/container';
import Hr from 'components/hr';
import TextBlock from 'components/text-block';
import Vr from 'components/vr';
import { isEven } from 'utils/math';

const Timeline = ({ startsOn, items }) => {
  const isMediaLeft = useCallback(
    (index) => (startsOn === 'l' ? isEven(index) : !isEven(index)),
    [startsOn]
  );

  return (
    <>
      {items.map(({ start, end, textBlock, image }, index) => (
        <div key={index}>
          <Hr />
          <Container className='grid-cols-12 lg:grid gap-gutter lg:min-h-[40vw] -lg:py-8'>
            <section
              className={classNames(
                'col-span-6 xl:flex items-center gap-gutter justify-between',
                {
                  'lg:order-3': isMediaLeft(index),
                }
              )}
            >
              <div
                className={classNames(
                  'lg:mt-12 xl:-mx-page-mx -lg:p-channel xl:-mt-[30%]',
                  {
                    'xl:order-2': isMediaLeft(index),
                  }
                )}
              >
                <div className='flex justify-between'>
                  <div
                    className={classNames('flex -lg:order-2', {
                      'lg:order-2': !isMediaLeft(index),
                    })}
                  >
                    <div className='flex'>
                      <Vr
                        className={classNames('h-full', {
                          'lg:ml-14': !isMediaLeft(index),
                        })}
                      />
                      <p
                        className={classNames(
                          'font-medium text-md xl:text-lg pb-3 -lg:ml-14 -lg:mr-3',
                          {
                            'mr-14 pl-5': isMediaLeft(index),
                            'ml-14 pr-5': !isMediaLeft(index),
                          }
                        )}
                      >
                        {start}-{end}
                      </p>
                      <Vr
                        className={classNames('h-full -lg:ml-8', {
                          'lg:mr-14': isMediaLeft(index),
                        })}
                      />
                    </div>
                  </div>
                  <div />
                </div>
                <Hr
                  className={classNames('-mx-page-mx -lg:mr-0', {
                    'lg:ml-0': isMediaLeft(index),
                    'lg:mr-0': !isMediaLeft(index),
                  })}
                />
              </div>
              <TextBlock
                as='h3'
                className='py-8 xl:max-w-[50%] lg:pb-20 xl:pt-20'
                size='md'
                {...textBlock}
              />
              <Hr className='lg:hidden -mx-page-mx' />
            </section>
            <div
              className={classNames(
                '-lg:hidden flex',
                isMediaLeft(index) ? 'lg:order-2' : 'justify-end'
              )}
            >
              <Vr />
            </div>
            <section
              className={classNames(
                'col-span-5 -mx-page-mx -lg:p-channel lg:py-channel',
                {
                  'lg:ml-0': !isMediaLeft(index),
                  'lg:mr-0': isMediaLeft(index),
                }
              )}
            >
              <div className='h-full relative'>
                <div className='-lg:h-[calc(100vw-32px)] -lg:max-h-[428px] mx-auto overflow-hidden lg:absolute lg:inset-0'>
                  <AnimatedInViewImage
                    className='absolute inset-0'
                    image={image}
                  />
                </div>
              </div>
            </section>
          </Container>
        </div>
      ))}
    </>
  );
};

Timeline.propTypes = {
  startsOn: PropTypes.oneOf(['l', 'r']),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      start: PropTypes.string.isRequired,
      end: PropTypes.string.isRequired,
      textBlock: PropTypes.shape(TextBlock.propTypes),
      image: PropTypes.shape(AnimatedInViewImage.propTypes.image),
    })
  ),
};

export default Timeline;

export const query = graphql`
  fragment Timeline on WpPage_Pagebuilder_Components_Timeline {
    fieldGroupName
    startsOn
    items {
      start
      end
      textBlock {
        preHeading
        heading
        subheading
        body
      }
      image {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 1920
              height: 1920
              transformOptions: { cropFocus: CENTER }
            )
          }
        }
      }
    }
  }
`;
