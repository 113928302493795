/**
 * getInt - Returns the parsed int value
 *
 * @param {number|string}  value     The number or string to get the int value of
 * @param {number}         [base=10] The radix
 *
 * @return {number|undefined} The parsed int if successful, undefined it not
 */
export const getInt = (value, base = 10) => {
  const parsed = parseInt(value, base);

  if (Number.isNaN(parsed)) return undefined;

  return parsed;
};

/**
 * isEven - Checks if a number is even.
 *
 * @param   {number}  number The number to ask if it is even.
 *
 * @return  {boolean} If the number is even.
 */
export const isEven = (number) => number % 2 === 0;
