import React from 'react';

/**
 * Return a react error message if enabled, null if not.
 *
 * @export
 * @param {String} error - The error message to display.
 * @return {React Template|null}
 */
export function maybeRenderError(error) {
  if (!+process.env.GATSBY_IS_PUBLIC) return <p>{error}</p>;

  return null;
}
