import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import AnimatedInViewImage from 'components/animated-in-view-image';
import Container from 'components/container';
import Hr from 'components/hr';
import NumberedTextBlocks from 'components/numbered-text-blocks';
import Vr from 'components/vr';

const PrinciplesWithMedia = ({ items, image, componentIndex = 1 }) => {
  return (
    <>
      <Hr />
      <Container className='grid-cols-2 gap-gutter lg:grid'>
        <div className='flex justify-between'>
          <NumberedTextBlocks
            componentIndex={componentIndex}
            hasPageIndex={true}
            items={items}
          />
          <Vr className='-l:hidden' />
        </div>
        <Hr className='-mx-page-mx lg:hidden' />
        <div className='-mx-page-mx p-channel lg:ml-0 lg:pl-0'>
          <div className='h-full relative'>
            <AnimatedInViewImage
              className='object-cover w-full h-full'
              containerClassName='-lg:h-[calc(100vw-32px)] -lg:max-h-[428px] mx-auto overflow-hidden lg:absolute lg:inset-0'
              image={image}
            />
          </div>
        </div>
      </Container>
    </>
  );
};

PrinciplesWithMedia.propTypes = {
  items: NumberedTextBlocks.propTypes.items,
  image: AnimatedInViewImage.propTypes.image,
  componentIndex: PropTypes.number,
};

export default PrinciplesWithMedia;

export const query = graphql`
  fragment PrinciplesWithMedia on WpPage_Pagebuilder_Components_PrinciplesWithMedia {
    fieldGroupName
    image {
      altText
      localFile {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    items {
      heading
      body
    }
  }
`;
