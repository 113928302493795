import classNames from 'classnames';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import defaults from 'lodash/defaults';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
import { useSpring, animated } from 'react-spring';

import { default as defaultSpring } from 'springs';

const AnimatedInViewImage = ({
  springConfig = {},
  className = '',
  containerClassName = '',
  hasZoom = false,
  image = {
    altText: '',
    localFile: { childImageSharp: { gatsbyImageData: {} } },
  },
  ...props
}) => {
  const { localFile: img, altText = '' } = image;

  const ref = useRef();
  const { inViewport } = useInViewport(ref, { threshold: 0.35 });

  const animStyles = useSpring({
    config: defaults({}, springConfig, defaultSpring),
    from: { height: '100%' },
    to: inViewport && { height: '0%' },
    delay: 200,
  });

  return (
    <div ref={ref} className={containerClassName}>
      <GatsbyImage
        alt={altText || ''}
        className={classNames(className, {
          'duration-200 hover:scale-125': hasZoom,
        })}
        image={getImage(img)}
        {...props}
      />
      <animated.div
        className='bg-white-off absolute inset-0 mt-auto'
        style={animStyles}
      />
    </div>
  );
};

AnimatedInViewImage.propTypes = {
  springConfig: PropTypes.object,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  hasZoom: PropTypes.bool,
  image: PropTypes.shape({
    altText: PropTypes.string.isRequired,
    localFile: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        gatsbyImageData: PropTypes.object,
      }),
    }).isRequired,
  }).isRequired,
};

export default AnimatedInViewImage;
