import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Container from 'components/container';
import Hr from 'components/hr';
import TextBlock from 'components/text-block';
import TextBlockAndButtons from 'components/text-block-and-buttons';

const Cta = ({ textBlock, links }) => {
  const { preHeading } = textBlock;

  delete textBlock.preHeading;

  return (
    <>
      <Hr />
      <section className='bg-white-off'>
        <Container className='py-10 lg:py-20 grid gap-gutter lg:grid-cols-2'>
          <div className='grid gap-gutter grid-cols-6'>
            <div className='col-span-5 lg:justify-self-end'>
              <div className='text-lg font-sans font-medium leading-normal mb-2 lg:mt-2'>
                {preHeading}
              </div>
            </div>
          </div>
          <TextBlockAndButtons links={links} textBlock={textBlock} />
        </Container>
      </section>
    </>
  );
};

Cta.propTypes = {
  textBlock: PropTypes.shape(TextBlock.propTypes),
  links: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.shape({
        target: PropTypes.string,
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
    })
  ),
};

export default Cta;

export const query = graphql`
  fragment Cta on WpPage_Pagebuilder_Components_Cta {
    fieldGroupName
    textBlock {
      body
      fieldGroupName
      heading
      preHeading
      subheading
    }
    links {
      fieldGroupName
      link {
        target
        title
        url
      }
    }
  }
`;
