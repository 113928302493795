import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Container from 'components/container';
import TextBlock from 'components/text-block';

const SimpleTextBlock = ({ textBlock }) => {
  return (
    <Container className='py-10 lg:py-20 flex flex-col items-center'>
      <TextBlock align='center' {...textBlock} />
    </Container>
  );
};

SimpleTextBlock.propTypes = {
  textBlock: PropTypes.shape(TextBlock.propTypes),
};

export default SimpleTextBlock;

export const query = graphql`
  fragment SimpleTextBlock on WpPage_Pagebuilder_Components_SimpleTextBlock {
    fieldGroupName
    textBlock {
      body
      heading
      preHeading
      subheading
    }
  }
`;
