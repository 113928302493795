import * as Dialog from '@radix-ui/react-dialog';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import AnimatedInViewImage from 'components/animated-in-view-image';
import Button from 'components/button';
import ExternalLink from 'components/external-link';
import Heading from 'components/heading';
import RichText from 'components/rich-text';
import SocialIcon from 'components/social-icon';
import { useDictionary } from 'hooks';
import template from 'utils/template';

const TeamMember = ({
  bio,
  image = {
    altText: '',
    localFile: { childImageSharp: { gatsbyImageData: {} } },
  },
  name,
  size,
  title,
  linkedInProfile = '',
}) => {
  const {
    bio: bioDictionary,
    phraseOwnersItem,
    phraseReadItem,
  } = useDictionary();

  return (
    <li
      className={classNames('flex flex-col gap-4', {
        'lg:gap-6': size === 'sm',
      })}
    >
      {image && (
        <div className='relative'>
          <AnimatedInViewImage
            className='object-cover w-full h-full'
            containerClassName={classNames('mx-auto overflow-hidden', {
              'max-w-[260px]': size === 'sm',
              'max-w-[398px]': size === 'lg',
            })}
            image={image}
          />
        </div>
      )}
      <div className='h-full flex flex-col justify-between'>
        <div>
          <Heading
            className={classNames({
              'mb-1 text-md lg:mb-2': size === 'sm',
              'mb-2 text-lg': size === 'lg',
            })}
            html={name}
            size='lg'
          />
          <p
            className={classNames({
              'text-md lg:text-[21px] mb-3 lg:mb-6': size === 'sm',
              'text-lg mb-6': size === 'lg',
            })}
          >
            {title}
          </p>
        </div>
        <Dialog.Root>
          <Dialog.Trigger asChild>
            <Button
              className={classNames({
                'text-md': size === 'sm',
                'text-lg': size === 'lg',
              })}
              label={template(phraseReadItem)({ item: bioDictionary })}
            />
          </Dialog.Trigger>
          <Dialog.Portal>
            <Dialog.Overlay className='inset-0 fixed bg-overlay z-50 animate-fade-in' />
            <Dialog.Content className='bg-white fixed top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 z-50 w-full max-w-screen-xl max-h-screen overflow-y-auto p-[9vw] focus:outline-none md:p-[5vw]'>
              <Dialog.Title className='sr-only'>
                {template(phraseOwnersItem)({
                  owner: name,
                  item: bioDictionary,
                })}
              </Dialog.Title>
              <div className='justify-between md:flex'>
                <div className='md:w-[40%]'>
                  <AnimatedInViewImage
                    className='object-cover w-full mb-10 -md:mt-10'
                    image={image}
                  />
                  <div className='-md:mb-10'>
                    <Heading
                      className={classNames({
                        'mb-1 text-md lg:mb-2': size === 'sm',
                        'mb-2 text-lg': size === 'lg',
                      })}
                      html={name}
                      size='lg'
                    />
                    <p
                      className={classNames({
                        'text-md lg:text-[21px]': size === 'sm',
                        'text-lg': size === 'lg',
                      })}
                    >
                      {title}
                    </p>
                  </div>
                </div>
                <div className='md:w-[50%] flex flex-col justify-between'>
                  <div>
                    <RichText html={bio} />
                  </div>
                  {linkedInProfile && (
                    <ExternalLink aria-label='LinkedIn' to={linkedInProfile}>
                      <SocialIcon className='mt-11' platform='LinkedIn' />
                    </ExternalLink>
                  )}
                </div>
              </div>
              <Dialog.Close asChild>
                <button
                  aria-label='Close'
                  className='absolute top-[9vw] right-[9vw] md:top-[2.5vw] md:right-[2.5vw] translate-x-1/2 -translate-y-1/2'
                >
                  <div className='w-[24px] h-[24px] relative flex items-center justify-center overflow-hidden'>
                    <div className='absolute h-[2px] m-auto w-[24px] rotate-45 bg-black' />
                    <div className='absolute w-[24px] h-[2px] m-auto -rotate-45 bg-black' />
                  </div>
                </button>
              </Dialog.Close>
            </Dialog.Content>
          </Dialog.Portal>
        </Dialog.Root>
      </div>
    </li>
  );
};

TeamMember.propTypes = {
  name: PropTypes.string.isRequired,
  bio: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    altText: PropTypes.string.isRequired,
    localFile: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        gatsbyImageData: PropTypes.object,
      }),
    }).isRequired,
  }).isRequired,
  linkedInProfile: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'lg']),
};

export default TeamMember;
