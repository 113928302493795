import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import ContactForm from 'components/contact-form';
import Container from 'components/container';
import Hr from 'components/hr';
import TextBlock from 'components/text-block';

const TextBlockAndContactForm = ({ contactForm, textBlock }) => {
  return (
    <>
      <Hr />
      <Container className='grid-cols-2 gap-gutter lg:grid'>
        <div className='relative py-10 lg:py-20'>
          <div className='absolute top-channel bottom-0 inset-x-channel bg-green -mx-page-mx -z-1 lg:mr-0 lg:bottom-channel lg:right-0' />
          <div className='grid-cols-6 gap-gutter lg:mr-page-mx xl:mr-0 xl:grid'>
            <div className='-xl:hidden' />
            <TextBlock
              isReversed
              className='-lg:pt-channel lg:col-span-4'
              size='md'
              {...textBlock}
            />
            <div className='-xl:hidden' />
          </div>
        </div>

        <div className='grid-cols-6 gap-gutter py-10 lg:py-20 xl:grid'>
          <ContactForm
            {...contactForm}
            className='lg:pl-gutter lg:col-span-5'
          />
        </div>
      </Container>
    </>
  );
};

TextBlockAndContactForm.propTypes = {
  contactForm: PropTypes.shape(ContactForm.propTypes),
  textBlock: PropTypes.shape(TextBlock.propTypes),
};

export default TextBlockAndContactForm;

export const query = graphql`
  fragment TextBlockAndContactForm on WpPage_Pagebuilder_Components_TextBlockAndContactForm {
    fieldGroupName
    contactForm {
      action
      confirmationMessage
      errorMessage
      fieldGroupName
      formId
      heading
      isJobApplication
    }
    textBlock {
      body
      fieldGroupName
      heading
      preHeading
      subheading
    }
  }
`;
