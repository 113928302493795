import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Container from 'components/container';
import Hr from 'components/hr';
import TextBlock from 'components/text-block';
import Video from 'components/video';
import Vr from 'components/vr';

const Opportunity = ({ sections, textBlock, video }) => {
  return (
    <>
      <Hr />
      <Container className='grid-cols-2 lg:grid gap-gutter'>
        <div className='flex'>
          <div className='flex-1 lg:mr-page-mx xl:mr-0'>
            <div className='col-span-4 my-10 lg:my-20'>
              <div className='mx-auto xl:max-w-4/6'>
                <TextBlock
                  as='h2'
                  className='mb-10 lg:mb-20'
                  size='md'
                  {...textBlock}
                />
              </div>
              {sections.map((section, id) => (
                <React.Fragment key={id}>
                  <Hr className='-mx-page-mx lg:mr-0' />
                  <div className='mx-auto xl:max-w-4/6'>
                    <TextBlock
                      as='h3'
                      className='my-5 lg:my-10'
                      size='sm'
                      {...section.textBlock}
                    />
                    {section.items?.length > 0 && (
                      <ul className='flex flex-row flex-wrap gap-4'>
                        {section.items.map((list, i) => (
                          <li
                            key={i}
                            className='flex-auto font-serif uppercase text-xs tracking-xl leading-x-open'
                          >
                            {list.item}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
          <Vr className='-lg:hidden' />
        </div>

        <div className='-mx-page-mx p-channel lg:ml-0 lg:pl-0'>
          <div className='h-full relative'>
            <Video
              {...video}
              autoPlay
              loop
              muted
              className='object-cover w-full h-full'
              controls={false}
            />
          </div>
        </div>
      </Container>
    </>
  );
};

Opportunity.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          item: PropTypes.string,
        })
      ),
      textBlock: PropTypes.shape(TextBlock.propTypes),
    })
  ),
  textBlock: PropTypes.shape(TextBlock.propTypes),
  video: PropTypes.shape(Video.propTypes),
};

export default Opportunity;

export const query = graphql`
  fragment Opportunity on WpPage_Pagebuilder_Components_Opportunity {
    fieldGroupName
    textBlock {
      body
      fieldGroupName
      heading
      preHeading
      subheading
    }
    sections {
      fieldGroupName
      items {
        item
      }
      textBlock {
        body
        fieldGroupName
        heading
        preHeading
        subheading
      }
    }
    video {
      image {
        altText
        sourceUrl
      }
      mp4 {
        mediaItemUrl
      }
      webm {
        mediaItemUrl
      }
    }
  }
`;
