import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import Container from 'components/container';
import Hr from 'components/hr';
import NumberedTextBlocks from 'components/numbered-text-blocks';
import TextBlock from 'components/text-block';
import { usePageIndex } from 'hooks';

const PrincipleWithTextBlocks = ({
  textBlock,
  items,
  componentIndex = 1,
  hasIndex = true,
}) => {
  const { addIndex, deleteIndex, getIndex } = usePageIndex();

  addIndex('principleWithTextBlocks');

  useEffect(() => {
    return function () {
      deleteIndex('principleWithTextBlocks');
    };
  }, [deleteIndex]);

  const Component = hasIndex ? (
    <NumberedTextBlocks
      componentIndex={componentIndex}
      items={[
        {
          body: textBlock.body,
          heading: textBlock.heading,
          preHeading: textBlock.preHeading,
          subHeading: textBlock.subHeading,
          pageIndex: getIndex('principleWithTextBlocks'),
        },
      ]}
    />
  ) : (
    <div className='py-16 gap-gutter grid-cols-6 lg:grid lg:py-28 lg:mr-page-mx xl:mr-0'>
      <TextBlock
        className='col-span-5 self-center lg:mx-auto'
        size='md'
        {...textBlock}
      />
      <div className='-xl:hidden' />
    </div>
  );

  return (
    <div>
      <Hr />
      <Container className='grid-cols-2 gap-gutter lg:grid'>
        {Component}
        <ul className='pb-16 lg:py-28'>
          {items.map((item, index) => (
            <li key={index}>
              {index > 0 && (
                <Hr className='-mr-page-mx ml-page-mx my-8 lg:-ml-gutter' />
              )}
              <TextBlock
                body={item.textBlock.body}
                className='ml-page-mx lg:ml-0'
                heading={item.textBlock.heading}
                preHeading={item.textBlock.preHeading}
                size='sm'
                subHeading={item.textBlock.subHeading}
              />
            </li>
          ))}
        </ul>
      </Container>
    </div>
  );
};

PrincipleWithTextBlocks.propTypes = {
  textBlock: PropTypes.shape(TextBlock.propTypes).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      textBlock: PropTypes.shape(TextBlock.propTypes),
    })
  ).isRequired,
  componentIndex: PropTypes.number,
  hasIndex: PropTypes.bool,
};

export default PrincipleWithTextBlocks;

export const query = graphql`
  fragment PrincipleWithTextBlocks on WpPage_Pagebuilder_Components_PrincipleWithTextBlocks {
    fieldGroupName
    textBlock {
      body
      fieldGroupName
      heading
      preHeading
      subheading
    }
    items {
      fieldGroupName
      textBlock {
        body
        fieldGroupName
        heading
        preHeading
        subheading
      }
    }
  }
`;
