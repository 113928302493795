import classNames from 'classnames';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import AnimatedInViewImage from 'components/animated-in-view-image';
import Container from 'components/container';
import Hr from 'components/hr';
import TextBlock from 'components/text-block';
import Video from 'components/video';
import Vr from 'components/vr';

const Hero = ({
  image,
  isHalf,
  mediaAlignment,
  mediaType,
  textBlock,
  video,
}) => {
  const isVideo = mediaType === 'video';

  return (
    <>
      <Hr />
      <Container className='grid-cols-12 gap-gutter lg:grid lg:min-h-[40vw]'>
        {isHalf && (
          <div
            className={classNames('-xl:hidden', {
              'lg:order-4': mediaAlignment === 'left',
            })}
          />
        )}
        <div
          className={classNames({
            'lg:order-3': mediaAlignment === 'left',
            'col-span-5 lg:mr-page-mx xl:mr-0 xl:col-span-4': isHalf,
            'col-span-3': !isHalf,
          })}
        >
          <TextBlock className='py-12 lg:py-32' size='md' {...textBlock} />
          <Hr className='lg:hidden -mx-page-mx' />
        </div>

        <div
          className={classNames(
            '-lg:hidden flex',
            mediaAlignment === 'left' ? 'lg:order-2' : 'justify-end'
          )}
        >
          {!(isHalf && mediaAlignment === 'left') && <Vr />}
        </div>

        <div
          className={classNames(
            '-mx-page-mx flex',
            mediaAlignment === 'left' ? 'lg:-mr-channel' : 'lg:-ml-channel',
            {
              'col-span-6': isHalf,
              'col-span-8': !isHalf,
            }
          )}
        >
          <div className='p-channel flex-1'>
            <div className='h-full relative'>
              {isVideo ? (
                <div className='-lg:h-[calc(100vw-32px)] -lg:max-h-[428px] mx-auto overflow-hidden lg:absolute lg:inset-0'>
                  <Video
                    {...video}
                    autoPlay
                    loop
                    muted
                    className='object-cover w-full h-full'
                    controls={false}
                  />
                </div>
              ) : (
                <AnimatedInViewImage
                  className='object-cover w-full h-full'
                  containerClassName='-lg:h-[calc(100vw-32px)] -lg:max-h-[428px] mx-auto overflow-hidden lg:absolute lg:inset-0'
                  image={image}
                />
              )}
            </div>
          </div>
          {isHalf && mediaAlignment === 'left' && (
            <div className='-lg:hidden'>
              <Vr />
            </div>
          )}
        </div>
      </Container>
    </>
  );
};

Hero.propTypes = {
  image: PropTypes.shape({
    altText: PropTypes.string.isRequired,
    localFile: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        gatsbyImageData: PropTypes.object,
      }),
    }).isRequired,
  }),
  isHalf: PropTypes.bool,
  mediaAlignment: PropTypes.string,
  mediaType: PropTypes.string,
  textBlock: PropTypes.shape(TextBlock.propTypes),
  video: PropTypes.shape(Video.propTypes),
};

export default Hero;

export const query = graphql`
  fragment Hero on WpPage_Pagebuilder_Components_Hero {
    fieldGroupName
    isHalf
    mediaAlignment
    mediaType
    textBlock {
      body
      heading
      preHeading
      subheading
    }
    image {
      altText
      localFile {
        childImageSharp {
          gatsbyImageData(width: 1920, height: 1920)
        }
      }
    }
    video {
      image {
        altText
        sourceUrl
      }
      mp4 {
        mediaItemUrl
      }
      webm {
        mediaItemUrl
      }
    }
  }
`;
