import classNames from 'classnames';
import padStart from 'lodash/padStart';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import Hr from 'components/hr';
import TextBlock from 'components/text-block';
import { usePageIndex } from 'hooks';

const NumberedTextBlocks = ({
  className = '',
  col = 'l',
  componentIndex = '',
  forceIndices = [],
  hasPageIndex = false,
  items,
  size = 'md',
  textBlockClassName = '',
}) => {
  const { addIndex, deleteIndex, getIndex } = usePageIndex();

  return (
    <ul className={className}>
      {items?.map((item, index) => {
        const indexKey = `numberedTextBlock-${componentIndex}-${index}`;

        if (hasPageIndex) addIndex(indexKey);

        useEffect(() => {
          return function () {
            if (hasPageIndex) deleteIndex(indexKey);
          };
        }, [deleteIndex]);

        return (
          <li key={index}>
            {index > 0 && (
              <Hr
                className={classNames('-mx-page-mx', {
                  'lg:mr-0': col === 'l',
                  'lg:ml-0': col === 'r',
                })}
              />
            )}
            <div className='py-16 lg:py-28 lg:mr-page-mx xl:mr-0'>
              <div className='grid-cols-6 gap-gutter xl:grid'>
                {col === 'r' && <div className='-xl:hidden' />}
                <span
                  aria-hidden='true'
                  className='mb-12 font-serif text-3xl leading-normal text-green text-center flex justify-center md:justify-start lg:justify-center lg:mb-6 xl:mb-0'
                >
                  {padStart(
                    (forceIndices.length
                      ? forceIndices[index]
                      : hasPageIndex
                      ? getIndex(indexKey)
                      : index) + 1,
                    2,
                    '0'
                  )}
                </span>
                <TextBlock
                  as='h3'
                  className={`col-span-4 ${textBlockClassName}`}
                  size={size}
                  {...item}
                />
              </div>
            </div>
            {col === 'l' && <div className='-xl:hidden' />}
          </li>
        );
      })}
    </ul>
  );
};

NumberedTextBlocks.propTypes = {
  className: PropTypes.string,
  col: PropTypes.oneOf(['l', 'r']),
  componentIndex: PropTypes.number,
  forceIndices: PropTypes.array,
  hasPageIndex: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      textBlock: PropTypes.shape(TextBlock.propTypes),
    })
  ).isRequired,
  pageIndex: PropTypes.number,
  size: PropTypes.string,
  textBlockClassName: PropTypes.string,
};

export default NumberedTextBlocks;
