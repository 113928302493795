import classNames from 'classnames';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Container from 'components/container';
import Heading from 'components/heading';
import Hr from 'components/hr';
import TeamMember from 'components/team-member';

const TeamMemberList = ({ heading, items, size }) => {
  return (
    <>
      <Hr />
      <Container
        className={classNames({
          'my-12 lg:my-20': size === 'sm',
          'my-20 lg:my-24': size === 'lg',
        })}
      >
        <Heading
          className={classNames({
            'text-md mb-12 lg:mb-20': size === 'sm',
            'text-lg mb-18 lg:mb-24': size === 'lg',
          })}
          html={heading}
          type='sans'
        />
        <ul
          className={classNames('gap-x-gutter gap-y-14 lg:gap-y-20', {
            'grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6':
              size === 'sm',
            'grid md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-5': size === 'lg',
          })}
        >
          {items.map((item, index) => (
            <TeamMember key={`${item.name}-${index}`} size={size} {...item} />
          ))}
        </ul>
      </Container>
    </>
  );
};

TeamMemberList.propTypes = {
  heading: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['sm', 'lg']),
  items: PropTypes.arrayOf(PropTypes.shape(TeamMember.propTypes)).isRequired,
};

export default TeamMemberList;

export const query = graphql`
  fragment TeamMemberList on WpPage_Pagebuilder_Components_TeamMemberList {
    fieldGroupName
    heading
    size
    items {
      bio
      fieldGroupName
      name
      title
      image {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(height: 500, width: 400, placeholder: BLURRED)
          }
        }
      }
      linkedInProfile
    }
  }
`;
