import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Container from 'components/container';
import Heading from 'components/heading';
import Hr from 'components/hr';
import LinkOrExternalLink from 'components/link-or-external-link';
import Map from 'components/map';
import TextBlock from 'components/text-block';
import Vr from 'components/vr';
import { useSettings } from 'hooks';

const ContactHero = ({ textBlock }) => {
  const { offices = [] } = useSettings();

  offices.sort((a) => (a.isPrimary ? -1 : 1));
  const primaryOffice = offices?.find(({ isPrimary }) => isPrimary);
  const {
    groupedContactLinks: { tel = [], mailto = [] } = { tel: [], mailto: [] },
  } = primaryOffice || {};

  const markers = offices.map((office, index) => {
    return {
      id: index,
      position: {
        lat: office.googleMap.latitude,
        lng: office.googleMap.longitude,
      },
    };
  });

  return (
    <>
      <Hr />
      <Container className='grid-cols-2 gap-gutter lg:grid'>
        <div className='gap-gutter flex-row lg:flex xl:grid grid-cols-6'>
          <div className='-xl:hidden' />
          <div className='col-span-4 my-12 lg:my-20 lg:mr-page-mx xl:mr-0'>
            <TextBlock as='h1' size='lg' {...textBlock} />
            {tel.length !== 0 || mailto.length !== 0 ? (
              <Hr className='mt-8 lg:mt-12 2xl:mt-16' />
            ) : null}
            {tel && (
              <ul className='text-md'>
                {tel.map(({ target, title, url }, index) => (
                  <div key={`${title}-${index}`}>
                    <li className='my-2'>
                      <a href={url} target={target}>
                        {title}
                      </a>
                    </li>
                    <Hr />
                  </div>
                ))}
              </ul>
            )}
            {mailto && (
              <ul className='text-md'>
                {mailto.map(({ target, title, url }, index) => (
                  <div key={`${title}-${index}`}>
                    <li className='my-2'>
                      <a href={url} target={target}>
                        {title}
                      </a>
                    </li>
                    <Hr />
                  </div>
                ))}
              </ul>
            )}
          </div>
          <Vr className='justify-self-end -lg:hidden' />
        </div>

        <div>
          <div className='h-[396px] lg:-mr-page-mx lg:p-channel lg:pl-0'>
            <Map markers={markers} />
          </div>

          <ul className='lg:flex gap-gutter items-start'>
            {offices.map(
              (
                {
                  name = '',
                  isPrimary,
                  googleMap: {
                    stateShort,
                    streetName,
                    streetNumber,
                    city,
                    postCode,
                  } = {
                    stateShort: '',
                    streetName: '',
                    streetNumber: '',
                    city: '',
                    postCode: '',
                    country: '',
                  },
                  groupedContactLinks: { tel = [], mailto = [], https = [] } = {
                    tel: [],
                    mailto: [],
                  },
                },
                index
              ) => (
                <li key={`${name}-${index}`} className='my-10 lg:w-full'>
                  <Heading as='h3' className='text-[26px]' html={name} />
                  <p className='text-sm'>
                    {city}, {stateShort}
                  </p>
                  <Hr className='my-2' />
                  <p className='text-sm'>
                    {`${streetNumber} ${streetName}`}
                    <br />
                    {`${city}, ${stateShort} ${postCode}`}
                  </p>
                  <Hr className='mt-2' />
                  {tel.length ? (
                    <ul className='text-sm'>
                      {tel.map(({ target, title, url }, index) => (
                        <li key={`${title}-${index}`}>
                          <div className='my-4'>
                            <a href={url} target={target}>
                              {title}
                            </a>
                          </div>
                          <Hr />
                        </li>
                      ))}
                    </ul>
                  ) : null}
                  {!isPrimary && mailto.length ? (
                    <ul className='text-sm'>
                      {mailto.map(({ target, title, url }, index) => (
                        <li key={`${title}-${index}`}>
                          <div className='my-4'>
                            <a href={url} target={target}>
                              {title}
                            </a>
                          </div>
                          <Hr />
                        </li>
                      ))}
                    </ul>
                  ) : null}
                  {!isPrimary && https.length ? (
                    <ul className='text-sm'>
                      {https.map(({ target, title, url }, index) => (
                        <li key={`${title}-${index}`}>
                          <div className='my-4'>
                            <LinkOrExternalLink target={target} to={url}>
                              {title}
                            </LinkOrExternalLink>
                          </div>
                          <Hr />
                        </li>
                      ))}
                    </ul>
                  ) : null}
                  <div className='bg-white-off h-[1px] -translate-y-full' />
                </li>
              )
            )}
          </ul>
        </div>
      </Container>
    </>
  );
};

ContactHero.propTypes = {
  textBlock: PropTypes.shape(TextBlock.propTypes),
};

export default ContactHero;

export const query = graphql`
  fragment ContactHero on WpPage_Pagebuilder_Components_ContactHero {
    fieldGroupName
    textBlock {
      body
      heading
      preHeading
      subheading
    }
  }
`;
