import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import PrincipleWithTextBlocks from 'components/principle-with-text-blocks';
import TextBlock from 'components/text-block';

const InvestmentTextBlockWithTextBlocks = ({ textBlock, items }) => {
  return (
    <PrincipleWithTextBlocks
      hasIndex={false}
      items={items}
      textBlock={textBlock}
    />
  );
};

InvestmentTextBlockWithTextBlocks.propTypes = {
  textBlock: PropTypes.shape(TextBlock.propTypes),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      textBlock: PropTypes.shape(TextBlock.propTypes),
    })
  ).isRequired,
};

export default InvestmentTextBlockWithTextBlocks;

export const query = graphql`
  fragment InvestmentTextBlockWithTextBlocks on WpPage_Pagebuilder_Components_InvestmentTextBlockWithTextBlocks {
    fieldGroupName
    items {
      fieldGroupName
      textBlock {
        body
        fieldGroupName
        heading
        preHeading
        subheading
      }
    }
    textBlock {
      body
      fieldGroupName
      heading
      preHeading
      subheading
    }
  }
`;
