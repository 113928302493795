import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Container from 'components/container';
import Hr from 'components/hr';
import NumberedTextBlocks from 'components/numbered-text-blocks';
import TextBlock from 'components/text-block';
import Vr from 'components/vr';

const TextBlockWithNumberedTextBlocks = ({
  textBlock,
  numberedTextBlocks: { items },
}) => {
  return (
    <>
      <Hr />
      <Container className='grid-cols-2 gap-gutter lg:grid'>
        <div className='gap-gutter flex-row lg:flex xl:grid grid-cols-6'>
          <div className='-xl:hidden' />
          <TextBlock
            className='my-20 lg:my-28 col-span-4 lg:mr-page-mx xl:mr-0'
            size='lg'
            {...textBlock}
          />
          <Hr className='-mx-page-mx lg:hidden' />
          <Vr className='justify-self-end -lg:hidden' />
        </div>
        <div className='lg:-ml-gutter'>
          <NumberedTextBlocks
            col='r'
            items={items.map((a) => a.textBlock)}
            textBlockClassName='lg:ml-page-mx xl:ml-0'
          />
        </div>
      </Container>
    </>
  );
};

TextBlockWithNumberedTextBlocks.propTypes = {
  textBlock: PropTypes.shape(TextBlock.propTypes),
  numberedTextBlocks: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        textBlock: PropTypes.shape(TextBlock.propTypes),
      })
    ),
  }),
};

export default TextBlockWithNumberedTextBlocks;

export const query = graphql`
  fragment TextBlockWithNumberedTextBlocks on WpPage_Pagebuilder_Components_TextBlockWithNumberedTextBlocks {
    fieldGroupName
    numberedTextBlocks {
      fieldGroupName
      items {
        fieldGroupName
        textBlock {
          body
          fieldGroupName
          heading
          preHeading
          subheading
        }
      }
    }
    textBlock {
      body
      fieldGroupName
      heading
      preHeading
      subheading
    }
  }
`;
