import { graphql } from 'gatsby';
import chunk from 'lodash/chunk';
import PropTypes from 'prop-types';
import React from 'react';

import Container from 'components/container';
import Hr from 'components/hr';
import TextBlock from 'components/text-block';
import Vr from 'components/vr';

const TextBlockGrid = ({ items }) => {
  const [firstGroup, secondGroup] = chunk(items, 3);

  return (
    <>
      <Hr />
      <Container className='grid-cols-12 gap-gutter lg:grid -lg:py-10'>
        <div className='col-span-5 lg:py-16 lg:grid grid-cols-6 gap-gutter'>
          <div className='col-span-5'>
            {firstGroup.map(({ textBlock }, index) => (
              <TextBlock key={index} as='h3' className='my-12' {...textBlock} />
            ))}
          </div>
          <div className='col-span-1' />
        </div>
        <Vr className='justify-self-end -lg:hidden' />
        <div className='col-span-6 lg:py-16 lg:grid grid-cols-6 gap-gutter'>
          <div className='col-span-1' />
          <div className='col-span-5'>
            {secondGroup.map(({ textBlock }, index) => (
              <TextBlock key={index} as='h3' className='my-12' {...textBlock} />
            ))}
          </div>
        </div>
      </Container>
    </>
  );
};

TextBlockGrid.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      textBlock: PropTypes.shape(TextBlock.propTypes),
    })
  ),
};

export default TextBlockGrid;

export const query = graphql`
  fragment TextBlockGrid on WpPage_Pagebuilder_Components_TextBlockGrid {
    fieldGroupName
    items {
      textBlock {
        preHeading
        heading
        subheading
        body
      }
    }
  }
`;
