import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Container from 'components/container';
import Hr from 'components/hr';
import TextBlock from 'components/text-block';

const ReversedTextBlockWithTextBlocks = ({ textBlock, items }) => {
  return (
    <>
      <Hr />
      <Container className='grid-cols-2 gap-gutter 2xl:grid-cols-3 lg:grid'>
        <div className='relative gap-gutter items-center lg:grid 2xl:col-span-2 2xl:grid-cols-8'>
          <div className='absolute top-channel bottom-0 inset-x-channel bg-green -mx-page-mx -z-1 lg:mr-0 lg:bottom-channel lg:right-0' />
          <div className='-2xl:hidden col-span-2' />
          <TextBlock
            {...textBlock}
            className='py-20 mx-page-mx col-span-2 2xl:col-span-3 2xl:mx-0'
            isReversed={true}
            size='md'
          />
          <div className='-2xl:hidden col-span-3' />
        </div>
        <ul className='-lg:mt-11 pb-16 lg:py-14 lg:ml-page-mx'>
          {items.map((item, index) => (
            <li key={index}>
              {index > 0 && (
                <Hr className='-mr-page-mx ml-page-mx my-8 lg:-ml-gutter' />
              )}
              <TextBlock
                body={item.textBlock.body}
                className='ml-page-mx lg:ml-0'
                heading={item.textBlock.heading}
                size='sm'
              />
            </li>
          ))}
        </ul>
      </Container>
    </>
  );
};

ReversedTextBlockWithTextBlocks.propTypes = {
  textBlock: PropTypes.shape(TextBlock.propTypes),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      textBlock: PropTypes.shape(TextBlock.propTypes),
    })
  ).isRequired,
};

export default ReversedTextBlockWithTextBlocks;

export const query = graphql`
  fragment ReversedTextBlockWithTextBlocks on WpPage_Pagebuilder_Components_ReversedTextBlockWithTextBlocks {
    fieldGroupName
    items {
      fieldGroupName
      textBlock {
        body
        fieldGroupName
        heading
        preHeading
        subheading
      }
    }
    textBlock {
      body
      fieldGroupName
      heading
      preHeading
      subheading
    }
  }
`;
